import React, { useEffect, useRef, useState } from "react";
import "./video.style.css";
import { posts as postsApi } from "../../api/posts";
import { useDispatch, useSelector } from "react-redux";
import { PLACEHOLDER } from "../../helpers/constant";
import { getFileType } from "../../helpers/utility";
import { setPlayer } from "../../redux/actions/app";

const FRAME_MAP = 10;

const Video = React.memo((props) => {
    const { src, muted, autoPlay, source, is_boosted } = props;
    const canvasRef = useRef();
    const videoRef = useRef();
    const startTime = useRef(0);
    const watched = useRef(0);
    const hasWatched = useRef(props.hasWatched);
    const requested = useRef(false);
    const [watchedPercent, setWatchedPercentage] = useState(0);
    const dispatch = useDispatch();
    const [thumbnail, setThumbnail] = useState("");
    // const videoType = getFileType(src);
    const prevPlayer = useSelector(store => store.app.player);

    const generateThumbnails = () => {
        const video = document.createElement("video");
        video.src = src;

        function* generateDuration() {
            for (let i = 0; i < FRAME_MAP; i++) {
                video.currentTime = (video.duration / FRAME_MAP) * i;
                yield i;
            }
        }

        let gen;

        video.addEventListener(
            "loadeddata",
            function () {
                gen = generateDuration();
                gen.next();
            },
            false
        );

        video.addEventListener("seeked", () => {
            gen?.next();
            const dimension = (canvasRef.current.width || 0) / FRAME_MAP;
            const context = canvasRef.current.getContext("2d");
            context.drawImage(video, dimension, 0, dimension, dimension);
        });
    };

    const onLoad = async (e) => {
        if (props.onLoad) props.onLoad(e);
        if (props.autoPlay) videoRef.current?.play();
        setVideoThumbnail();
    };

    const setVideoThumbnail = () => {
        const video = videoRef.current;

        // Wait for the canvas to render before capturing the thumbnail
        setTimeout(() => {
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnail = props.poster;

            // Set the thumbnail as state
            console.log(props.poster);
            setThumbnail(thumbnail);

            // Set the poster attribute to the captured thumbnail data URL
            video.setAttribute("poster", thumbnail);

            // Set the current time back to the beginning of the video
            video.currentTime = 0;
        }, 500);
    };

    const onSeeked = async (e) => {
        if (props.onSeeked) props.onSeeked(e);
    };

    const handlePlay = async () => {
        console.log(prevPlayer);
        // stop previous player, then send this one there
        if (prevPlayer) {
            if (prevPlayer !== videoRef.current) {
                await prevPlayer.pause();
            }
        }

        dispatch (setPlayer(videoRef.current));

        startTime.current = Date.now();
    };

    const handleTimeUpdate = () => {
        if (!props.is_boosted) {
            if (hasWatched.current || requested.current) return;
        }

        let w = (Date.now() - startTime.current) / 1000;

        startTime.current = Date.now();

        watched.current = w + watched.current;

        const percentage = watched.current / videoRef.current?.duration;

        if (percentage >= 0.25 && percentage < 0.5) {
            setWatchedPercentage(25);
        } else if (percentage >= 0.5 && percentage < 0.75) {
            setWatchedPercentage(50);
        } else if (percentage >= 0.75 && percentage < 0.9) {
            setWatchedPercentage(75);
        } else if (percentage >= 0.9) {
            setWatchedPercentage(100);
            watched.current = videoRef.current?.duration;
        }
    };

    useEffect(() => {
        if (watchedPercent > 0) {
            if (!hasWatched.current || (props.is_boosted && watchedPercent <= 100)) {
                requested.current = true;

                let data = {
                    is_boosted,
                    total_duration: videoRef.current?.duration,
                    streaming_duration: watched.current,
                };

                if (source) {
                    data.source = source;
                    data.shared = true;
                }
                dispatch(
                    postsApi({
                        param_path: `${props.postId}/viewed`,
                        method: "POST",
                        data,
                    })
                )
                    .then((response) => {
                        if (response.success) {
                            hasWatched.current = true;
                        }
                    })
                    .catch((err) => {
                        requested.current = false;
                    });
            }
        }
    }, [watchedPercent]);

    return (
        <>
            <video
                preload="metadata"
                muted={muted}
                autoPlay={autoPlay || props.form}
                onTimeUpdate={handleTimeUpdate}
                onPlay={handlePlay}
                className={props.className}
                width={props.width}
                height={props.height}
                ref={videoRef}
                controls
                style={{ ...props.style, maxHeight: props.fullScreen ? "100%" : "350px" }}
                onLoadedData={onLoad}
                onSeeked={onSeeked}
            >
                <source src={`${src}${!props.form ? "#t=0.001" : ""}`} />
            </video>
        </>
    );
});

Video.defaultProps = {
    className: "",
    style: {},
    muted: false,
    autoPlay: false,
    is_boosted: false,
};

export default Video;
